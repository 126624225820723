import { useNavigate } from 'react-router-dom'
import { TPLS } from '@open-tender/types'
import { ButtonLink } from 'components'
import Heading from 'components/Heading'
import Body from 'components/Body'
import ThirdPartyLoyaltyProgress from './ThirdPartyLoyaltyProgress'
import {
  TPLSSummary,
  TPLSSummaryContent,
  TPLSSummaryInfo,
  TPLSSummaryPoints,
  TPLSSummaryPointsHeader,
  TPLSSummaryStatus,
  TPLSSummaryStatusSep,
} from './ThirdPartyLoyalty.styled'
import { Star } from 'components/icons'
import { formatDollars, roundPoints } from '@open-tender/utils'
import { useTheme } from '@emotion/react'
import { selectBrand, useAppSelector } from '@open-tender/cloud'

const ThirdPartyLoyaltySummary = ({
  isAccount,
  isSimple,
  first_name,
  last_name,
  phone,
  email,
  credit,
  points,
  progress,
  membership_tier,
  is_verified,
}: TPLS & { isAccount: boolean; isSimple?: boolean }) => {
  const navigate = useNavigate()
  const { tpls } = useAppSelector(selectBrand) || {}
  const { colors } = useTheme()
  const pointsBalance = points ? points.balance : null
  const creditBalance = credit ? credit.balance : null
  const hasCredit = !!(creditBalance && parseFloat(creditBalance))
  const showSeparator = pointsBalance !== null && hasCredit
  const isVerified = tpls !== 'PUNCHH' || is_verified
  const isSparkfly = tpls === 'SPARKFLY'
  const location = window.location.href
  const isPennSandbox =
    location.includes('penn-station.sandbox') || location.includes('localhost')

  if (isSparkfly && !isPennSandbox) return null

  return (
    <TPLSSummary isAccount={isAccount} isSimple={isSimple}>
      <TPLSSummaryContent isSimple={isSimple}>
        <TPLSSummaryInfo>
          <Heading as="p" size="xBig" style={{ marginLeft: -1 }}>
            {first_name} {last_name}
          </Heading>
          {!isSimple ? (
            <>
              <Body as="p" size="xSmall">
                {phone}
              </Body>
              <Body as="p" size="xSmall">
                {email}
              </Body>
            </>
          ) : null}
          {membership_tier && (
            <Heading as="p" size="small" style={{ marginTop: 10 }}>
              Membership Tier: {membership_tier || 'n/a'}
            </Heading>
          )}
          {isVerified ? (
            !isSimple ? (
              <Body as="p" size="small" style={{ marginTop: 10 }}>
                <ButtonLink onClick={() => navigate('/rewards')}>
                  View Rewards
                </ButtonLink>
              </Body>
            ) : null
          ) : (
            <>
              <Body
                as="p"
                size="small"
                style={{ marginTop: 10, color: colors.error }}
              >
                Your account has not yet been verfied, which is required for
                earning & redeeming rewards.
              </Body>
              <Body as="p" size="small" style={{ marginTop: 10 }}>
                <ButtonLink onClick={() => navigate('/account/settings')}>
                  Click here to verify your account.
                </ButtonLink>
              </Body>
            </>
          )}
        </TPLSSummaryInfo>
        {isVerified && (
          <TPLSSummaryStatus>
            {hasCredit && creditBalance ? (
              <TPLSSummaryPoints>
                <TPLSSummaryPointsHeader>
                  {/* <Star width={36} height={36} strokeWidth={1.5} /> */}
                  <Heading size="xxBig">
                    {formatDollars(creditBalance.toString())}
                  </Heading>
                </TPLSSummaryPointsHeader>
                <Body size="xSmall">Credit Balance</Body>
                <Body size="xSmall" style={{ marginTop: 5 }}>
                  <ButtonLink onClick={() => navigate('/order-type')}>
                    Use your credit
                  </ButtonLink>
                </Body>
              </TPLSSummaryPoints>
            ) : null}
            {showSeparator ? <TPLSSummaryStatusSep /> : null}
            {pointsBalance !== null ? (
              <TPLSSummaryPoints>
                <TPLSSummaryPointsHeader>
                  <Star size={36} strokeWidth={1.5} color={colors.tertiary} />
                  <Heading size="xxBig" color="tertiary">
                    {roundPoints(pointsBalance)}
                  </Heading>
                </TPLSSummaryPointsHeader>
                <Body size="small">Points Balance</Body>
                {!isSparkfly && (
                  <Body size="xSmall" style={{ marginTop: 5 }}>
                    <ButtonLink onClick={() => navigate('/points-shop')}>
                      Redeem your points
                    </ButtonLink>
                  </Body>
                )}
              </TPLSSummaryPoints>
            ) : null}
          </TPLSSummaryStatus>
        )}
      </TPLSSummaryContent>
      {progress && <ThirdPartyLoyaltyProgress progress={progress} />}
    </TPLSSummary>
  )
}

export default ThirdPartyLoyaltySummary
