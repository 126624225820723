import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { CustomerSignIn, FormFieldType } from '@open-tender/types'
import { useOneTimePasscodeForm } from '@open-tender/utils'
import {
  closeModal,
  resetOneTimePasscode,
  selectBrand,
  selectCustomer,
  selectOneTimePasscode,
  selectRecaptcha,
  sendOneTimePasscode,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Body,
  ButtonLink,
  ButtonSubmit,
  FormError,
  FormInputs,
  FormRecaptcha,
  FormSubmit,
  Recaptcha,
} from 'components'

const OneTimePasscodeFormFooter = styled.div`
  margin: 4rem 0 -1.5rem;

  p {
    margin: 0;
  }
`

const OneTimePasscodeForm = ({
  callback,
  signUpFooter,
}: {
  callback?: () => void
  signUpFooter?: React.ReactNode
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const { use_enterprise_recaptcha } = useAppSelector(selectBrand) || {}
  const { otpSent, loading, error } = useAppSelector(selectOneTimePasscode)
  const { auth } = useAppSelector(selectCustomer)
  const { login: includeRecaptcha } = useAppSelector(selectRecaptcha) || {}

  const submit = useCallback(
    (data: CustomerSignIn) => dispatch(sendOneTimePasscode({ data })),
    [dispatch]
  )

  const loginCallback = () => {
    if (recaptchaRef.current) recaptchaRef.current.reset()
    callback?.()
  }

  const {
    submitRef,
    recaptchaRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useOneTimePasscodeForm(
    otpSent,
    loading,
    error,
    includeRecaptcha,
    submit,
    loginCallback
  )

  const isSubmitButtonDisabled =
    includeRecaptcha && !use_enterprise_recaptcha && !recaptchaToken

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  const resetForm = () => {
    dispatch(resetOneTimePasscode())
  }

  const signUp = () => {
    dispatch(closeModal())
    navigate(`/signup`)
  }

  useEffect(() => {
    if (error) {
      setRecaptchaToken(null)
    }
  }, [error])

  useEffect(() => {
    if (auth) dispatch(closeModal())
  }, [dispatch, auth])

  return (
    <>
      <form id="otp-form" onSubmit={handleSubmit} noValidate>
        <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
        <FormInputs
          fields={fields}
          data={data}
          onChange={onChange}
          errors={errors}
        />

        {includeRecaptcha && (
          <FormRecaptcha>
            <Recaptcha
              onChange={setRecaptchaToken}
              recaptchaRef={recaptchaRef}
            />
          </FormRecaptcha>
        )}

        <FormSubmit>
          <ButtonSubmit
            disabled={isSubmitButtonDisabled}
            submitRef={submitRef}
            submitting={submitting}
          >
            {submitting ? 'Submitting...' : 'Submit'}
          </ButtonSubmit>
        </FormSubmit>
      </form>
      <OneTimePasscodeFormFooter>
        {otpSent ? (
          <Body as="p" size="small">
            Didn't receive a security code?{' '}
            <ButtonLink onClick={resetForm}>
              Re-enter your phone or email.
            </ButtonLink>
          </Body>
        ) : (
          signUpFooter || (
            <Body as="p" size="small">
              Don't have an account yet?{' '}
              <ButtonLink onClick={signUp}>Sign up here.</ButtonLink>
            </Body>
          )
        )}
      </OneTimePasscodeFormFooter>
    </>
  )
}

export default OneTimePasscodeForm
